import axios from "axios";
import jwt_decode from "jwt-decode";
import userActions from "../store/actions/userActions";
import store from "../store/configureStore";

export const API_URL = process.env.REACT_APP_API_KEY;
const http = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    "Content-type": "application/json"
  }
});
http.interceptors.request.use(
  async (config) => {
    const user = store?.getState()?.userSlice?.user;
    let currentDate = new Date();
    if (user?.access) {
      const decodedToken: { exp: number } = jwt_decode(user?.access);
      if (decodedToken.exp * 1000 + 900000 > currentDate.getTime()) {
        if (config?.headers) {
          config.headers["Authorization"] = `Bearer ${store?.getState()?.userSlice?.user?.access
            }`;
        }
      } else {
        store?.dispatch(userActions.logout())
        return;
      }
    }
    return config;
  },
  (error) => {
    return error;
  }
);
http.interceptors.response.use(response => response, async error => {
  const status = error.response ? error.response.status : null
  const user = store?.getState()?.userSlice?.user;

  if ((status === 403) && user?.access) {
    await store.dispatch(userActions.refreshToken());
    if (error.config?.headers) {
      error.config.headers['Authorization'] = `Bearer ${store?.getState()?.userSlice?.user?.access
        }`;
      error.config.baseURL = undefined;
      return http.request(error.config);
    }
  }

  return Promise.reject(error);
});
export default http;